export function detectOS() {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.includes("windows")) {
    return "Windows";
  } else if (userAgent.includes("mac")) {
    return "MacOS";
  } else if (userAgent.includes("android")) {
    return "Android";
  } else if (userAgent.includes("linux")) {
    return "Linux";
  } else if (userAgent.includes("ios")) {
    return "iOS";
  } else {
    return "Unknown";
  }
}
