import * as React from 'react';
import { LanguagesOption, LanguagesSelect, LanguagesSelectElement, LanguagesWrapper } from './styled';

import livstickApp from '../../models/livstick';

import { useLocation } from 'react-router';
import useWebsite from '../../hooks/websiteHooks';
import { language } from '../../models/livstick';

const Languages: React.FunctionComponent = () => {
  const {website} = useWebsite();
  const location = useLocation();
  const languages:any = website.languages;
  console.log("languages", languages.map(l => l.get('uid')), {language});
  return <LanguagesWrapper className="language-wrapper">
    {languages && languages.length > 1 &&
    <LanguagesSelectElement>
      <LanguagesSelect location={location} onChange={e => {
        livstickApp.changeLang(e.currentTarget.value);
      }}>
        {languages.map(lang => <LanguagesOption selected={website.currentLanguage.code == lang.get('uid')} value={lang.get('uid')}>{lang.get('uid').toUpperCase()}</LanguagesOption>)}
        
      </LanguagesSelect>
    </LanguagesSelectElement>}
  </LanguagesWrapper>;
}

export default Languages;
