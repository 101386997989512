import Parse from 'parse';
import { useEffect, useState } from "react";

class Website {

  platform: any;
  services: any;
  languages: any;
  language: any;
  currentLanguage: {languageObject?: any, code?: string};
  init = async (setPending:any) => {
    const lightningRequest = await fetch("https://lightning.stage.livstick.com", {
      method: "POST",
      body: JSON.stringify({
        domain: window.location.host
      }),
    })

    const {platform, translations, assets, styles, languages, lang} = await lightningRequest.json();

    const _platform = Parse.Object.fromJSON({...platform, objectId: platform._id, className: "Platform"})

    this.platform = _platform;

    this.services = _platform.get('services');

    const _languages = languages.map(_l => Parse.Object.fromJSON({..._l, objectId: _l._id, className: "Language"}));

    this.languages = _languages;
    let currentLanguage = _languages.find(_l => {
      return _l.get('uid') === lang;
    });
    

    this.currentLanguage = {};
    this.currentLanguage.languageObject = currentLanguage;
    this.currentLanguage.code = lang;

    console.log({currentLanguage, lang})

    this.translations = translations;

    this.theme = { ...styles, ...assets };
    this.assetsList = { ...styles, ...assets };
    this.loaded = true;
    setPending(false);
  }

  fetchTranslation = async (lang) => {
    const lightningRequest = await fetch("https://lightning.stage.livstick.com", {
      method: "POST",
      body: JSON.stringify({
        domain: window.location.host,
        language: lang
      }),
    })

    const {platform, translations, assets, styles, languages} = await lightningRequest.json()

    const _platform = Parse.Object.fromJSON({...platform, objectId: platform._id, className: "Platform"})

    this.platform = _platform;

    this.services = _platform.get('services');

    const _languages = languages.map(_l => Parse.Object.fromJSON({..._l, objectId: _l._id, className: "Language"}));
    

    this.languages = _languages;
    let currentLanguage = _languages.find(_l => {
      return _l.get('uid') === navigator.language.split(/[-_]/)[0];
    });

    if (!currentLanguage) {
      currentLanguage = _languages.find(_l => {
        return _l.objectId === _platform.get('defaultLanguage').objectId;
      });
    }

    this.currentLanguage = {};
    this.currentLanguage.languageObject = currentLanguage;
    this.currentLanguage.code = currentLanguage.get('uid');

    this.translations = translations;

    this.theme = { ...styles, ...assets };
    this.assetsList = { ...styles, ...assets };
    this.loaded = true;
  }
  translations: any;
  countries: Parse.Object<Parse.Attributes>[];
  theme: any;
  assetsList: any;
  loaded = false;
}

export const WEBSITE = new Website();

const useWebsite = () => {
  const [website, setWebsite] = useState(WEBSITE);
  const [isPending, setPending] = useState(false);

  const setLanguageWebsite = async (lang) => {
    if(website) {
      setPending(true);
      await website.fetchTranslation(lang);
      setPending(false)
    }
  }

  useEffect(() => {
   if(!website.loaded){
    setPending(true);
    website.init(setPending);
   }
  }, [])
  return {website, isPending, setLanguageWebsite};
}

export default useWebsite;